<template>
  <div style="max-width: 400px">
    <div class="neo-card">
      <v-form ref="verifyForm" v-model="valid" lazy-validation>
        <h2 class="heading-size-4 mb-4">{{ $t("verifyPhoneTitle") }}</h2>
        <div class="neo-text-field">
          <div>{{ $t("code") }}</div>
          <v-otp-input
            v-model="smsCode"
            type="number"
            length="6"
            @finish="codeReady"
            :disabled="checkingCode"
          ></v-otp-input>
          <div>
            {{
              !!$store.state.user.phoneNumber
                ? $t("phoneVerified")
                : userMessage
            }}
          </div>
        </div>
        <v-btn
          v-if="!$store.state.user.phoneNumber"
          ref="getBtn"
          id="get-code-button"
          class="mt-4"
          block
          :disabled="waiting || checkingCode"
          color="primary"
          @click="getCode"
        >
          {{
            `${this.firstCode ? $t("sendCode") : $t("resendCode")} ${
              countdown > 0 ? " " + countdown : ""
            }`
          }}
          <!-- <v-icon size="32" v-if="waiting" class="ml-1 waiting">
            mdi-tire
          </v-icon> -->
        </v-btn>
      </v-form>
    </div>
  </div>
</template>

<script>
import { firebase } from "@/helpers/firebase";

export default {
  name: "VerifyPhone",
  props: {
    phoneNumber: String,
  },
  data() {
    return {
      smsCode: "",
      userMessage: " ",
      manualRecaptcha: undefined,
      waiting: false,
      recaptchaOk: false,
      valid: true,
      rules: {
        error: () => true || "",
        required: (value) => !!value || "Required.",
      },
      disableGetCode: false,
      confirmationResult: undefined,
      recaptchaVerifier: undefined,
      checkingCode: false,
      countdownInterval: 0,
      countdown: 0,
      firstCode: true,
    };
  },
  watch: {
    phoneNumber() {
      this.smsCode = "";
      this.firstCode = true;
    },
  },
  methods: {
    blockSendCode() {
      this.countdown = 0;
      clearInterval(this.countdownInterval);
      this.waiting = true;
    },
    clearError() {
      this.userMessage = " ";
      this.rules.error = true;
    },
    handleEnter() {
      this.$refs.sendBtn.$el.click();
    },
    codeReady() {
      setTimeout(() => {
        this.checkingCode = true;
        if (this.confirmationResult) this.checkCode();
        else {
          this.userMessage = this.$t("requestNewCode");
          this.checkingCode = false;
        }
      }, 1500);
      // this.$emit('codeReady');
    },
    async getCode() {
      this.smsCode = "";

      const appVerifier = this.recaptchaVerifier;
      this.waiting = true;
      this.countdown = 60;
      clearInterval(this.countdownInterval);
      this.countdownInterval = setInterval(() => {
        this.countdown -= 1;
        if (this.countdown === 3) this.userMessage = this.$t("noSmsReceived");
        if (this.countdown === 1) {
          this.countdown = 0;
          this.waiting = false;
          clearInterval(this.countdownInterval);
        }
      }, 1000);
      firebase.auth().languageCode = this.$store.state.lang;
      const currentUser = await firebase.auth().currentUser;
      currentUser
        .linkWithPhoneNumber(this.phoneNumber, appVerifier)
        .then((confirmationResult) => {
          this.confirmationResult = confirmationResult;
          this.checkingCode = false;
          this.userMessage = `${this.$t("promptCode")} ${this.phoneNumber}`;
          if (this.firstCode) this.firstCode = false;

          // ...
        })
        .catch((error) => {
          // Error; SMS not sent
          // ...
          if (error.code === "auth/too-many-requests") {
            this.blockSendCode();
            this.userMessage = this.$t("blockedDevice");
          } else {
            console.log(error);
            this.recaptchaVerifier.render().then(
              function (widgetId) {
                this.recaptchaVerifier.recaptcha.reset(widgetId);
              }.bind(this)
            );
          }
        });
    },
    async checkCode() {
      this.checkingCode = true;
      if (this.confirmationResult) {
        this.confirmationResult
          .confirm(this.smsCode)
          .then(() => {
            // User signed in successfully.
            //   const user = result.user;
            this.$emit("phoneVerified");
          })
          .catch((error) => {
            // User couldn't sign in (bad verification code?)
            // ...
            this.checkingCode = false;
            switch (error.code) {
              case "auth/invalid-verification-code":
                this.userMessage = this.$t("wrongPhoneCode");
                break;
              case "auth/phone-number-already-exists":
                this.userMessage = this.$t("phoneExists");
                this.blockSendCode();
                break;
              case "auth/account-exists-with-different-credential":
                this.userMessage = this.$t("phoneExists");
                this.blockSendCode();
                break;
              case "auth/code-expired":
                this.userMessage = `${this.$t("codeExpired")} ${this.$t(
                  "requestNewCode"
                )}`;
                break;
              case "auth/invalid-phone-number":
                this.userMessage = this.$t("invalidPhone");
                this.blockSendCode();
                break;
              default:
                this.userMessage = this.$t("noSmsReceived");
                break;
            }
          });
      } else {
        // error message, get a new code
        this.checkingCode = false;
        this.waiting = false;
        this.userMessage = this.$t("noSmsReceived");
      }
    },
  },
  mounted() {
    if (!this.$store.state.user.phoneNumber) {
      this.waiting = true;
      this.checkingCode = false;
      firebase.auth().languageCode = this.$store.state.lang;
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "get-code-button",
        {
          size: "invisible",
          callback: () => {
            this.$refs.getBtn.$el.click();

          },
          "expired-callback": () => {
            // Response expired. Ask user to solve reCAPTCHA again.
            console.log("bad recaptcha");
            // ...
          },
        }
      );

      this.recaptchaVerifier.render();
      this.recaptchaVerifier.verify();
    } else {
      this.$emit("phoneVerified");
    }
  },
};
</script>
